

import Vue from "vue";
import VueRouter from "vue-router";
import RouterPrefetch from "vue-router-prefetch";
import DashboardPlugin from "./plugins/dashboard-plugin";
import App from "./App.vue";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import Swal from 'sweetalert2';
import { store } from "./store/index";
import VueDayjs from "@/plugins/dayjs";


import VueGoodTable from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css';

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import { BVToastPlugin } from "bootstrap-vue";
import VueDatePicker from "@mathieustan/vue-datepicker";
import "@mathieustan/vue-datepicker/dist/vue-datepicker.min.css";

import { TimeSelect } from 'element-ui'
// router setup
import router from "./routes/router";
import i18n from "./i18n";
import "./registerServiceWorker";
// plugin setup
Vue.use(DashboardPlugin);
Vue.use(VueRouter);

Vue.use(VueGoodTable);

Vue.use(VueDayjs);
Vue.use(BVToastPlugin);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueDatePicker, {
  lang: "en",
});


Vue.use(RouterPrefetch);
Vue.use(VueSweetalert2);
Vue.use(TimeSelect);
Vue.directive("click-outside", {
  bind: function(el, binding) {
    // Define ourClickEventHandler
    const ourClickEventHandler = (event) => {
      if (!el.contains(event.target) && el !== event.target) {
        // as we are attaching an click event listern to the document (below)
        // ensure the events target is outside the element or a child of it
        binding.value(event); // before binding it
      }
    };
    // attached the handler to the element so we can remove it later easily
    el.__vueClickEventHandler__ = ourClickEventHandler;

    // attaching ourClickEventHandler to a listener on the document here
    document.addEventListener("click", ourClickEventHandler);
  },
  unbind: function(el) {
    // Remove Event Listener
    document.removeEventListener("click", el.__vueClickEventHandler__);
  },
});
window.addEventListener('storage', async (event) => {
  if (event.key === 'access_token' && !event.newValue) {
    // Token was removed
    console.log('Detected token deletion. Checking for refresh token.');

    const refreshToken = localStorage.getItem('refresh_token');
    if (!refreshToken) {
      // No refresh token, log out
      localStorage.setItem('logoutNotification', 'true');
      window.location.href = '/logout';
    } else {
      try {
        // Try to get a new access token
        await store.dispatch("getNewAccessToken");
      } catch (error) {
        // Error occurred, log out
        console.error('Error getting new access token:', error);
        localStorage.setItem('logoutNotification', 'true');
        window.location.href = '/logout';
      }
    }
  }
});
document.addEventListener('DOMContentLoaded', () => {
  const notification = localStorage.getItem('logoutNotification');
  if (notification === 'true') {
    Swal.fire({
      icon: 'error',
      title: 'Logged Out',
      text: i18n.t('login.loggedout'),
      toast: false,
      position: 'top',
      showConfirmButton: false,
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
      showConfirmButton: true,
      confirmButtonText: 'OK',
    });

    localStorage.removeItem('logoutNotification');
  }
});
/* eslint-disable no-new */
new Vue({
  store,
  el: "#app",
  render: (h) => h(App),
  router,
  
  i18n,
});
