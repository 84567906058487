import dayjs from 'dayjs'
import { en, de } from 'dayjs/locale/en'
import utc from 'dayjs/plugin/utc'
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
export default {
	install: function(Vue) {

		dayjs.locale(de)
		dayjs.locale(en)
		dayjs.extend(utc)

		Object.defineProperties(Vue.prototype, {
			$dayjs: {
				get: function() {
					return dayjs
				},
				utcOffset: undefined
			}
		})
		Vue.dayjs = dayjs
	}
}
