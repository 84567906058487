<template>
  <router-view></router-view>
</template>

<script>
import { TokenStorage } from "/src/modules/token-storage.js";
export default {
  methods: {
    initializeLayout() {
      if (!this.$rtl.isRTL) {
        // Just make sure rtl css is off when we are not on rtl
        this.$rtl.disableRTL();
      }
    }
  },
  async mounted() {
    if (localStorage.getItem('role')) {
      if (localStorage.getItem('role') === 'user') {
        this.$store.commit('setRole', 'user');
      } else if (localStorage.getItem('role') === 'electrician') {
        this.$store.commit('setRole', 'electrician');
      }
    } else {
      localStorage.setItem('role', 'user');
    }
    if (localStorage.getItem('darkMode') === null) {
      localStorage.setItem('darkMode', JSON.stringify(true));
    }
    // sessionStorage.setItem('loaded', 'true');

    const waitForDispatch = async () => {
      this.$store.commit("startDashLoading");
      await this.$store.dispatch('get_sns')
      this.$store.commit("stopDashLoading");
    }

    await TokenStorage.isAuthenticated() && waitForDispatch()

    this.initializeLayout();
  },
};
</script>
