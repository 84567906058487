import DashboardLayout from 'src/pages/Layout/DashboardLayout.vue';
import AuthLayout from 'src/pages/Pages/AuthLayout.vue';
import Logout from 'src/pages/Pages/Logout.vue';
import VueRouter from "vue-router";
// GeneralViews
import NotFound from 'src/pages/GeneralViews/NotFoundPage.vue';

// Calendar
const Calendar = () =>
  import(/* webpackChunkName: "extra" */ 'src/pages/Calendar/CalendarRoute.vue');
// Charts
const Charts = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Charts.vue');


const Settings = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Settings.vue');
const Update = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Update.vue');


//ElectricianSettings
const ElectricianSettings = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/ElectricianSettings.vue');






// Dashboard pages
const Dashboard = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Dashboard.vue');

// ElectricianDashboard 
const ElectricianDashboard = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/ElectricianDashboard.vue');


const Login = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/Login.vue');
const Register = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/Register.vue');

const Lock = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/Lock.vue');
const PasswordReset = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/PasswordReset.vue');

const LoginCleverPV = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/Login_clerverpv.vue');





let authPages = {
  path: '/',
  component: AuthLayout,
  name: 'Authentication',
  children: [
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/register',
      name: 'Register',
      component: Register
    },
    {
      path: '/login_cleverpv',
      name: 'LoginCleverPV',
      component: LoginCleverPV
    },

    {
      path: '/lock',
      name: 'Lock',
      component: Lock
    },
    {
      path: "/password_reset",
      name: "PasswordReset",
      component: PasswordReset,
    },
  ]
};

const routes = [
  {
    path: '/',
    redirect: '/login',
    name: 'login'
  },
  {
    path: "/logout",
    name: "logout",
    component: Logout,
  },



  authPages,
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/dashboard',
    name: 'Dashboard layout',
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        components: { default: Dashboard }
      },
      {
        path: 'electrician-dashboard',
        name: 'ElectricianDashboard',
        components: { default: ElectricianDashboard }
      },
      {
        path: 'calendar',
        name: 'Calendar',
        components: { default: Calendar }
      },
      {
        path: 'charts',
        name: 'Charts',
        components: { default: Charts }
      },
      {
        path: 'update',
        name: 'Update',
        components: { default: Update }
      },
      {
        path: 'settings',
        name: 'Settings',
        components: { default: Settings }
      },
      {
        path: 'electrician-settings',
        name: 'ElectricianSettings',
        components: { default: ElectricianSettings }
      },

      
    ]
  },
  
];


export default routes;
